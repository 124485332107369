/* eslint-disable react/no-danger */
import dynamic from 'next/dynamic';
import { memo } from 'react';
import { DialogGuideWrapper } from './styled';

const DialogContainer = dynamic(() => import('@components/DialogContainer'));

interface Props {
  width?: number | string;
  visible: boolean;
  onCancel: () => void;
}

export const DialogGuide = ({ visible, onCancel, width }: Props) => {
  return (
    <DialogContainer
      visible={visible}
      onCancel={onCancel}
      maskClosable
      centered
      className="dialogGuide"
      hiddenCloseButton
      width={width || '720px'}
      bodyStyle={{ maxWidth: '720px', width: '100%' }}
    >
      <DialogGuideWrapper className="popup popupGuide">
        <div className="--wrapper">
          <div className="--close" onClick={onCancel}>
            <i className="far fa-times" aria-hidden></i>
          </div>
          {/* <img
            className="--bg"
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/guide.png`}
          /> */}
          {/* <div className="--title">
            <h1>
              Chào mừng tới <strong>Bảo tàng số 30 năm MobiFone</strong>
            </h1>
            <span>
              Vui lòng làm theo các hướng dẫn để tham quan trải nghiệm
            </span>
          </div> */}
          <div className="--logo">
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/guide1.png`}
              alt=""
            />
          </div>
          <div className="--content">
            <div className="--sliderWrapper">
              <div className="itemGuide">
                <img
                  className="--icon"
                  src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/popup guide.png`}
                />
                {/* <span>
                  Visit the lobby to get tickets, discover events and activities
                </span> */}
              </div>
              {/* <div className="itemGuide">
                <img
                  className="--icon"
                  src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/guide-2.png`}
                />
                <span>Go to the stages to access shows and concerts</span>
              </div> */}
            </div>
          </div>
          {/* <div className="--buttons">
            <div className="btn btnSecondary" onClick={onCancel}>
              ĐÓNG
            </div>
          </div> */}
        </div>
        <img
          className="--footer"
          src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/guide2.png`}
          alt=""
        />
      </DialogGuideWrapper>
    </DialogContainer>
  );
};

export default memo(DialogGuide);
